$cyan: #03abc7;

.loading-container {
    background-color:white;
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    align-items: center;
}

.box {
    width:50px;
    height:50px;
}