@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,800&display=swap');

$cyan: #03abc7;
$cyan_bg: green;
$green :#ACCB4B;
$white: #ffffff;
$lightGrey: #f0f0f0;
$textGrey: #A3A3A3;
$grey50: #757575;
$sliderGrey: #C7C7C7;
$grey: #9F9F9F;
$black: #000000;
$red: #F26363;
$red2: #F25757;
$blackish: #3B4043;
$mapLoading: #e6e4e0;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MapContainer {
    display:flex;
    flex-direction: column;
    flex:1;
    background-color: $mapLoading;
    justify-content: flex-end;

  }

  .MapContainerMeasure {
    display:flex;
    flex:1;
    z-index: 4;
    background-color: red;

  }


.MapOverlay {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
    flex:1;
    z-index: 2;
}

.TopBar {
    background-color: $white;
    pointer-events: all;
    height: 35px;
    display: flex;
    align-items: center;
    width: 100%;

    //spotify
    flex-direction: row;
    .spotify-button {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 15px;
    }
}

.BottomBar {
    padding:10px;
    background-color: $white;
    pointer-events: all;
}
.BottomBar.loading {
  padding-top:20px;
  padding-top:10px;
}
.Message-Row {
    display:flex;
    justify-content: center;
    flex-direction: row;
}
.Message-Input-Container {
  display:flex;
  flex:1;
  flex-direction: row;
  background-color: $lightGrey;
  border-radius: 22px;
  padding-left:10px;

}
.Message-Input-Container.error {
  border:solid;
  border-color: red;

  }

.Message-Input {
    border: none;

    //border-radius: 22px;
    padding:10px;
    margin-right:30px;
    font-size:18px;
    flex:1;
    width: 100%;
    box-sizing: border-box;
    background-color: $lightGrey;
}
.Message-Input.red-placeholder::placeholder {
    color: red;
}

.Message-Location {
  font-family: "Lato";
  font-weight: 700;
  color: $textGrey;
  font-family: 14px;
  margin-bottom:15px;

}
.Message-Location-Info {
  font-family: "Lato";
  font-weight: 400;
  color: $textGrey;
  font-family: 14px;
}

.Message-Input:focus {
    outline: none;
}
.Send-Button {
    width: 50px;
    height: 50px;
    margin-left: 5px;
}
.Message-Input::placeholder {
    color: black;
    font-size:18px;
}
.Message-Location-Container {
display: flex;
flex-direction: column;
flex:1;
height: 100px;
}

.Slider-Container {
  flex-grow:1;
}

.Distance-Label {
  color:$red2;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 14px;
  margin-top:17px;
  margin-bottom:8px;

}

.Message-Location-Container-Mobile {
  display: flex;
  flex-direction: column;
  height: 200px;

  .center-mobile-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .Message-Location {
      font-family: "Lato";
      font-weight: 700;
      color: $textGrey;
      font-family: 14px;
    }

    .Slider-Container {
      flex-grow:1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Distance-Label {
      color:$red2;
      font-family: "Montserrat";
      font-weight: 800;
      font-size: 14px;
    }

    label {
      display: block;
      text-align: center;
    }
  }

  .ok-button-mobile-container {

    width: 100%;
    display: flex;
    justify-content: center;
    .ok-button-mobile {
      width: 145px;
      height: 45px;
      background-color: $cyan;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 35px;

      label {
        color: white;
        font-family: "Montserrat";
        font-size: 14px;
      }
    }

    .ok-button-mobile:active {
      background-color: #009ab5;
    }
  }
}

.Message-Location-Container label {
    display:block;
    text-align: center;
}

.Loading-Overlay {
  display: flex;
  flex: 1;
  background-color: $cyan;
  justify-content: center;
  align-items:center;
}


.TopBar-Title {
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 18px;
    color: $cyan;
    margin: 0;
    margin-left: 25px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background: $sliderGrey;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $red;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $red;
  cursor: pointer;
}

.spotify-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.spotify-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  width: 85%;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;

  p {
    padding: 0 28px 0 28px;
  }

  input {
    width: 90%;
    height: 30px;
    border:0;
    text-align: center;
    background-color: $lightGrey;
  }

  button {
    margin: 20px 0 15px 0;
    padding: 10px 20px 10px 20px;
    font-size: 18px;
    font-weight: 600;
    background-color: $white;
    border-radius: 25px;
  }

  button.spotify-send {
    background-color: $cyan;
    color: $white;
  }

  .spotify-help-gif {
    width: 100%;
    height: auto;
  }
}

.desktop-modal {
  width: 30%;

  img {
    width: 100%;
    height: auto;
  }
}

.Navi {
display:flex;
flex-direction: row;
flex:1;
padding: 0px 25px;
justify-content:space-between;
pointer-events: all;
background-color: white;

}

.Navi-item {
  background-color: white;
  text-align:center;
}

.flex-one {
  display:flex;
  padding-top:110px;
  min-height: 90vh;

}
.flex-new {
  display:flex;
  flex:1;
  overflow-y: scroll;
}

.Home-bg {
  display: flex;
  flex-direction: column;
  flex:1;
  background-image: url("../img/racepeps_background.jpg");
  background-attachment: fixed;
  background-repeat: repeat;
  position: relative;
}

.welcome-text-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  position: relative;

  .center-text-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    label {
      color: white;
      font-size: 22px;
      font-weight: 600;
      font-family: "Montserrat";
    }
  }

  .bottom-info-arrow-container {
    position: absolute;
    bottom: 8px;
    left: 35px;
  }

  .bottom-right-info-arrow-container {
    position: absolute;
    bottom: 8px;
    right: 25px;
  }

  .bottom-info-text-container {
    position: absolute;
    bottom: 25px;
    left: 65px;

    label {
      color: white;
      font-size: 18px;
      font-weight: 600;
      font-family: "Montserrat";
    }
  }

  .bottom-right-info-text-container {
    position: absolute;
    bottom: 25px;
    right: 55px;

    label {
      color: white;
      font-size: 18px;
      font-weight: 600;
      font-family: "Montserrat";
    }
  }
}

.Navi-link-container {
  padding:13px;
  flex-direction: row;
  display: flex;
  align-items: center;
  .Navi-link {
  text-decoration: none;
  color: $grey;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 14px;
}


}
.Navi-link-container.active {
    border-bottom: 5px solid $cyan;
      .Navi-link {
  color: $cyan;
      }
}

.Message-card {
  background-color:white;
  margin:15px;
  padding:15px;
  display: flex;
  flex-direction: row;
  align-items:center;
  border-radius:5px;
  .message {
    margin-left:10px;
  }
  @media screen and (min-width: 1200px) {
    width: 40%;
  }
}

.Spotify-card {
  display: flex;
  background-color:white;
  margin:15px;
  flex-direction: row;
  align-items:center;
  border-radius:5px;
  @media screen and (min-width: 1200px) {
    width: 40%;
  }
}

.urlAttempt {
  display: flex;
  flex-direction: row;
  align-items:center;
  background-color: $blackish;
  height: 50px;
  justify-content: center;

  label {
    color: white;
    font-size: 15px;
    font-weight: 700;
  }
}

.dm {
  width:50px;
  height:50px;
  background-image:url('../svg/dm_grey.svg');
  background-size:contain;
  display:flex;
  align-items: center;
  justify-content: center;

  label {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 11px;
  color: $textGrey;
  margin-bottom:4px;
}

}

.dm.active {
  background-image:url('../svg/dm_redfill.svg');
  label {
color: $white;
  }
}
.dm.set {
  background-image:url('../svg/dm_red.svg');
  label {
    color: $red;
  }
}

.dm.history {
  background-image:url('../svg/dm_cyan.svg');
  background-repeat: no-repeat;
  background-position: center;
  label {
    color: $white;
    font-size: 14px;
    margin-bottom:6px;
  }
}

.dm-spotify-history {
  width:50px;
  height:50px;
  background-image:url('../svg/dm_cyan.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size:contain;
  display:flex;
  align-items: center;
  justify-content: center;
  label {
    color: $white;
    font-size: 14px;
    margin-bottom:6px;
    font-family: "Montserrat";
    font-weight: 800;
  }
}

.dm-spotify-history.warning {
  width:35px;
  height:35px;
  background-image:url('../svg/dm_redfill.svg');
  label {
    margin-bottom: 3px;
  }
}

.dm-spotify-history-container {
  width:95px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.dm-live {
  width: 10px;
  height: 50px;
}

.more-bg {
  display: flex;
  flex: 1;
  background-color: $lightGrey;
  flex-direction: column;
  max-height: 100vh;
}

.more-bg h1, h2{
  font-size:18px;
  font-weight: 800;
  text-align:left;
  margin-left: 25px;
}

.more-bg h1 {
  color: $cyan
}

.more-bg h2 {
    color:$black;
}

.more-bg h6 {
  text-align: center;
}

.info-box {
  background-color:white;
  border-radius: 5px;
  flex: 1;
}

.row {
  display:flex;
  flex-direction:row;
  flex:1;
}
.row-5 {
  display:flex;
  flex-direction:row;
  padding:12px;
  flex:5;
  align-items: center;
  justify-content: space-between;
  img {
    margin-right: 5px;
  }
}

.row-more {
  display:flex;
  flex-direction: row;
  flex:1;
  align-content: center;
  margin-right:20px;
  margin-left:20px;
  margin-bottom:20px;

}

.key {
  font-size:14px;
  font-family: Lato;
  color:$grey50;
  flex:2;


}
.val {
  font-size:14px;
  font-family: Lato;
  color:$blackish;
  flex:2;

}
.loader {
    background-color: $cyan;
z-index: 51;
position:fixed;
left:0;
right:0;
display:flex;
flex-direction: row;
justify-content: center;
padding:20px;
label {
  color: $white;
  font-size:20px;
  font-family: Lato;
  text-align: center;

}


}
 .loader.sent {
    background-color: $green;
  }

.main-div {
  display:flex;
  flex:1;
  height:100%;
  flex-direction: column;
  justify-content: space-around;
}

.rec-circle {
  width: 16px;
  height:16px;
  border-radius: 999px;
  background-color: $red;
}

.voicemessage-row {
  display:flex;
  flex-direction: row;
  align-items: center;

  flex-grow:1;
  padding-right:20px;
}
.voicemessage-row.space-between {

    justify-content: space-between;
}

.cyan-bold-18 {
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 18px;
    color: $cyan;
}

.center-xy {
  justify-content:center;
  align-items:center;
}

.pad-left-7 {
  padding-left:7px;
}

.in-base-width {
  max-width:420px;
  overflow: hidden;
}

.switch-scene-button {
    position: fixed;
    top: 70px;
    right: 20px;
    z-index: 5;
    width:100px;
    height:100px;
    display:flex;
    align-items:center;
    justify-content: center;
    border-radius: 10px;
    label {
      font-size: 16px;
      color:white;
      font-family: "Montserrat";
      text-shadow: 0 0 6px #000000CC;
      white-space: pre-wrap;
      text-align:center;
    }
}

.switch-scene-button.image-bg {
  background-image: url("../img/show_map.png");
}

.switch-scene-button.color-bg {
  background-color: $cyan;
}

.audiomessage-label {
  padding-left:12px;
  font-size: 18px;

}

.cyan {
color: $cyan;
}

.race-title {
  font-size: 22px;
  font-family: "Montserrat";
  font-weight: 800;
  text-align: center;
  color: $cyan;

  img {
    width: 25px;
    height: 25px;
    float: right;
    margin-right: 15px;
    cursor: pointer;
  }
}

.units-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left:10px;

  h2 {
    margin: 0;
    margin-left: 15px;
    align-self: flex-start;
  }

  .units-switches {
    display: flex;
    flex-direction: row;
  }

  .units-label-active {
    font-size: 18px;
    font-weight: 800;
    padding: 10px 30px;
    color: $cyan;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .units-label-inactive {
    font-size: 18px;
    font-weight: 800;
    padding: 10px 30px;
    color: $textGrey;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  img {
    margin-right: 5px;
  }

  img:hover {
    cursor: pointer;
  }
}

.live-separator-line {
  border: 1px solid $textGrey;
  margin: 20px 15px;
}

.static-content {
  flex: 1;
}

.live-content {
  flex: 4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;

  .runner-status-container {
    flex: 1;
    display: flex;
    background-color: $white;
    min-height: 80px;
    max-height: 80px;
    align-items: center;

    .runner-status-column {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .header-label {
        font-family: 'Montserrat';
        font-size: 14;
        padding-bottom: 3px;
      }
    }
  }

  .response-container {
    flex: 5;
    overflow: auto;
    padding-left: 20px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column-reverse;

      li {

        .response-row {
          display: flex;
          align-items: center;

          .audio-response-container {
            display: flex;
            background-color: $white;
            border-radius: 30px;
            justify-content: space-around;
            align-items: center;
            padding: 10px;
            margin: 5px;
            width: 125px;
            height: 15px;
            flex-direction: row;

            .audio-label-text {
              font-family: 'Lato';
              font-size: 18px;
              display: flex;
              flex-direction: row;
              user-select: none;
            }
          }

          .emoji-text {
            font-size: 40px;
            margin-right: 5px;
            margin-top: 5px;
            user-select: none;
          }

          .timestamp-label-text {
            color: $textGrey;
            font-size: 14px;
            font-family: 'Lato';
            user-select: none;
          }
        }
      }
    }
  }

  .spotify-container {
    width: 100%;
    flex: 1;

    iframe {
      border: none;
    }
  }
}

.mobile {
  display: flex;
  flex: 1;
  margin: 15px 15px;

  h2 {
    color: $white;
  }

  .runner-status-container {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .response-container {
    background-color: $lightGrey;

    .no-responses {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        color: $textGrey;
        font-family: Lato;
        font-size: 14px;
      }
    }
  }

  .spotify-container {
    border: none;
    margin-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.onboarding-overlay {
  position: absolute;
  z-index: 6;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;

  .onboarding-form {
    width: 350px;
    height: 250px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .onboarding-welcome-label {
      font-size: 20px;
      color: $cyan;
      font-weight: bold;
      font-family: "Montserrat";
    }

    .onboarding-text-container {
      width: 235px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      label {
        font-size: 18;
      }
    }

    .onboarding-text-input {
      width: 300px;
      height: 45px;
      border: none;
      background-color: $lightGrey;
      font-size: 18px;
      text-align: center;
    }

    .onboarding-submit-input-empty {
      width: 135px;
      height: 45px;
      border: 2px solid $grey;
      background-color: none;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        color: $grey;
        font-size: 14px;
        font-weight: bold;
        font-family: "Montserrat";
        user-select: none;
      }
    }

    .onboarding-submit-input-filled {
      width: 135px;
      height: 45px;
      border: 2px solid $cyan;
      background-color: $cyan;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        color: white;
        font-size: 14px;
        font-weight: bold;
        font-family: "Montserrat";
        user-select: none;
      }
    }

    .onboarding-submit-input-filled:hover {
      cursor: pointer;
      background-color: #02bfe0;

      label {
        cursor: pointer;
      }
    }

    .onboarding-submit-input-filled:active {
      cursor: pointer;
      background-color: #009ab5;

      label {
        color: $lightGrey;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;

  label {
    color: $textGrey;
    font-size: 12px;
    user-select: none;

    a {
      font-size: 12px;
      user-select: none;
    }
  }
}

.recaptcha-row-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;

  label {
    color: $textGrey;
    font-size: 8px;
    user-select: none;

    a {
      font-size: 8px;
      user-select: none;
    }
  }
}

.error-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  label {
    font-family: "Montserrat";
    font-size: 18px;
    color: $cyan;
  }
}

.event-over-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  label {
    font-family: "Montserrat";
    font-size: 22px;
    color: $red;
  }
}

.emojis-on-map-container {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 4px solid $cyan;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    font-size: 20px;
  }
}

.help-button-mobile-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.help-button-mobile {
  background-color: $cyan;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 45px;
  border-radius: 30px;

  label {
    font-family: "Montserrat";
    color: white;
    user-select: none;
  }
}

.language-select {
margin-top:10px;
width: 95%;
height:50px;
-moz-appearance: none;
-webkit-appearance: none;
appearance: none;
padding: 10px 15px;
border:0;
outline:none;
font-size: 14px;
font-family: Lato;
text-indent: 10%;
background-origin: content-box;
background-size: contain, 20px;
background-repeat: no-repeat;
background-position: left, right;
border-radius: 5px;

}

.language-select.fi {
background-image: url("../img/flags/fi.png"), url("../img/select_arrow_down.svg");

}
.language-select.en {
background-image: url("../img/flags/en.png"), url("../img/select_arrow_down.svg");

}

.unit {
font-size: 16px;
color: $cyan;
font-family: Montserrat;
font-weight: 500;
}

.unit.active {
font-size: 20px;
color: $black;
font-weight: 800;

}

.pre-line {
  white-space: pre-wrap !important;
}

.us-browser-container {
  background-color:#03ABC7;
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.threedots-img {
  margin: 15px 0px;
}

.us-browser-help {
  margin: 15px 30px;
  background-color: white;
  padding:25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.somebrow-title {
font-size: 24px;
color:white;
text-align:center;
font-weight: 800;
font-style: normal;
font-stretch: normal;
line-height: 1.25;
letter-spacing: normal;

}

.somebrow-label {
color:#6F6F6F;
font-size: 20px;
font-weight: bold;
font-style: normal;
font-stretch: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: center;
}

.continue-Anyway {
font-size: 20px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: center;
color: #ffffff;
margin-bottom:55px;
text-decoration: underline;
}

.break-on-n {
  white-space: pre-line;
}
.refresh-label {
  margin-top:20px;
  text-align: center;
}